import { observer } from 'mobx-react';
import { NavigateFunction } from 'react-router-dom';
import styled from 'styled-components';
import { COLOR } from '../../fonts/color';
import { TEXT } from '../../fonts/text';
import {
  useChatStore,
  useDealStore,
  useInfluencerStore
} from '../../stores/initialize/Context';
import { ChatMessage } from '../../types/chatMessage';
import { PLACEHOLDER_ANOTHERDOT, difTime } from '../../utils';
import { determinePage } from '../../utils/Navigation';
import BackButton from '../../components/atoms/Layout/BackButton';
import InfluencerDetail from './InfluencerDetail';
import RBrandLogo from '../../components/atoms/RBrandLogo';
import { PlatformType } from '../../types/deal';

enum StatusText {
  pending = 'คำขอใหม่',
  ongoing = 'กำลังดำเนินการ',
  check = 'ตรวจงาน',
  done = 'เสร็จสิ้น'
}

interface MessageDeterminerProps {
  message: ChatMessage;
}

const messageDeterminer = (props: MessageDeterminerProps) => {
  const { message } = props;
  if (message?.video) {
    return 'วีดิโอ';
  } else if (message?.image) {
    return 'รูปภาพ';
  } else {
    return message?.text || 'ไม่มีข้อความ';
  }
};

interface Props {
  navigation: NavigateFunction;
  queryDealId: string;
  queryStatus: 'pending' | 'ongoing' | 'check' | 'done';
  queryDealContactId: string;
}

const InfluencerManagementDrawer = (props: Props) => {
  const { navigation, queryDealContactId, queryDealId, queryStatus } = props;

  const { deal } = useDealStore();
  const { influencers } = useInfluencerStore();
  const { lastMessages } = useChatStore();
  const { dealId } = deal;
  const drawerDealId = dealId || queryDealId;
  const dealPlatform = deal.platforms[0];

  const components: JSX.Element[] = [];
  const influencerList = influencers?.[queryStatus] || [{ data: [] }];
  influencerList.data.map(influencer => {
    const {
      influencer: influencerDetail,
      status,
      dealContactId,
      workUpdated,
      waitingForEdit,
      lastMessageAt
    } = influencer;
    const {
      displayName,
      name,
      img,
      followers_count,
      followers_count_facebook,
      followers_count_tiktok
    } = influencerDetail;
    const message = messageDeterminer({ message: lastMessages[dealContactId] });
    const lastMessageTimestamp = difTime(
      lastMessageAt || new Date(),
      true,
      true
    );
    const getFollower = (dealPlatform: PlatformType): number => {
      switch (dealPlatform) {
        case 'FACEBOOK':
          return followers_count_facebook || 0;
        case 'INSTAGRAM':
          return followers_count || 0;
        case 'TIKTOK':
          return followers_count_tiktok || 0;
        default:
          return 0;
      }
    };

    components.push(
      <InfluencerDetail
        name={displayName || name}
        profileImage={img || PLACEHOLDER_ANOTHERDOT}
        waitingForEdit={waitingForEdit}
        workUpdated={workUpdated}
        followers={getFollower(dealPlatform.toUpperCase() as PlatformType)}
        active={queryDealContactId === dealContactId}
        status={status}
        message={message}
        lastMessageAt={lastMessageTimestamp}
        onClick={() =>
          navigation(
            `/influencer?dealId=${drawerDealId}&dealContactId=${dealContactId}&status=${status}&page=${determinePage(
              status
            )}&platform=${deal.platforms[0].toUpperCase()}`
          )
        }
      />
    );
  });

  return (
    <>
      <PlaceholderContainer />
      <HeaderContainer>
        <RBrandLogo navigation={navigation} />
        <BackButton navigation={navigation} />
        <GrayMBu3Text>
          {StatusText[queryStatus]} ({influencerList.metadata.count})
        </GrayMBu3Text>
        {components}
      </HeaderContainer>
    </>
  );
};

export default observer(InfluencerManagementDrawer);

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 240px;
  padding: 20px 16px;
  height: 100%;
  z-index: 9999;
  border-right: 0.6px solid ${COLOR.Gray_200};
  background-color: ${COLOR.White};
`;

const PlaceholderContainer = styled.div`
  width: 240px;
  flex-shrink: 0;
`;

const LogoContainer = styled.img`
  margin-top: 8px;
  width: 120px;
  height: 23px;
  margin-right: 20px;
  align-self: center;
`;

const GrayMBu3Text = styled(TEXT.Bu3_Reg)`
  margin-top: 20px;
  margin-left: 12px;
  color: ${COLOR.Gray_M};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 90%;
`;
